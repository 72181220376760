import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { Component } from '@angular/core'
import { DataService } from './data.service'
import { UIHelpers } from 'src/app/helpers/ui-helpers'
import { Router } from '@angular/router'
import { IAlertService } from 'src/app/libs/ialert/ialerts.service'

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent {
    registrationError: string
    changePasswordForm: FormGroup
    oldPass: any
    newPass: any
    conPass: any
    form : any
    inprogress: boolean = false
    breadCrum: any = [
        {
            link: '/mentor/change-password',
            value: 'Change Password'
        }
    ]
    constructor(
        private fb: FormBuilder,
        private dataService: DataService,
        public ui: UIHelpers,
        private router: Router,
        private alert: IAlertService
    ) {
        this.changePasswordForm = this.fb.group({
            oldPassword: new FormControl(null, [Validators.required]),
            newPassword: new FormControl(null, [Validators.required]),
            newPasswordConfirmation: new FormControl(null, [Validators.required])
        })
    }


    get g() {
        return this.changePasswordForm.controls
    }

    changePassword(data: any) {
        this.inprogress = true
        this.oldPass = this.changePasswordForm.controls.oldPassword.value
        this.newPass = this.changePasswordForm.controls.newPassword.value
        this.conPass = this.changePasswordForm.controls.newPasswordConfirmation.value

        if (data.status === 'INVALID') {
            this.alert.error('Please fill-in valid data in all fields & try again.')
            this.inprogress = false

            return
        } else if (this.newPass !== this.conPass) {
            this.alert.error('New Password and Confirm Password do not match')
            this.inprogress = false
            return
        }
         else if (this.newPass === this.conPass) {
            if (this.newPass === this.oldPass) {
                this.alert.error('New and Old password cannot be same')
                this.inprogress = false
                return
            }
        }
        

        this.dataService.changePassword(data.value).subscribe((resp: any) => {
            this.inprogress = false
            if (resp.success == false) {
                if(resp.errors.general ==="Old password did no match"){
                    this.alert.error("Incorrect Old Password")
                    return
                }
            }
            this.alert.success('Password Changed Successfully')
            this.changePasswordForm.reset()
            this.changePasswordForm.controls.oldPassword.setErrors(null)
            this.changePasswordForm.controls.newPassword.setErrors(null)
            this.changePasswordForm.controls.newPasswordConfirmation.setErrors(null)
        })
    }
}
